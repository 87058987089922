import React, { useEffect, useRef, useState } from "react";
import OnboardHeading from "../OnboardHeading";
import moment from "moment";
import Select from "react-select";
import { toast } from "react-toastify";
import { autocloseTiming } from "../../api/regex";
import { getData, postData } from "../../api";
import { Form, InputGroup, Spinner } from "react-bootstrap";
import SearcIcon from "../../assets/images/multi-card-approval/search-icon.svg";
import DownArrow from "../../assets/images/multi-card-approval/downArrowBlue.svg";
import RightArrow from "../../assets/images/multi-card-approval/right_arrow.svg";
import LeftArrow from "../../assets/images/multi-card-approval/left_arrow.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { debounce } from "lodash";
import ModalDetail from "../multiCardDetail/modalDetail";
import Loader from "../Loader";
import { MdClear } from "react-icons/md";
import MultiCardTable from "./multiCardTable";
import ApprovalModal from "./approvalModal";
import "./style.scss";
import PendingModal from "./pendingModal";

export default function MultiCard() {
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const ref = useRef(null);
  const location = useLocation();

  const [employeeHoursData, setEmployeeHoursData] = useState([]);
  const [ptoStatus, setPtoStatus] = useState("pending");
  const [loading, setLoading] = useState(false);
  const [groupId, setGroupId] = useState(0);
  const [ptoPendingData, setPtoPendingData] = useState([]);
  const [ptoDeniedData, setPtoDeniedData] = useState([]);
  const [ptoApprovedData, setPtoApprovedData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [checkedValues, setCheckedValues] = useState([]);
  const [, setAllChecked] = useState(false);
  const [allIds, setAllIds] = useState([]);
  const [loginUserId] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [searchData, setSearchData] = useState("");
  const [exportModal, setExportModal] = useState(false);
  const [exportModalText, setExportModalText] = useState("");
  const [frequencyData, setFrequencyData] = useState([]);
  const [dateRangeData, setDateRangeData] = useState({});

  const [selectTagValue, setSelectTagValue] = useState(() => {
    const params = new URLSearchParams(location.search);
    const frequencyId = params.get("frequency");
    if (frequencyId && frequencyData.length > 0) {
      const initialFrequency = frequencyData.find(
        (option) => option.id === Number(frequencyId)
      );
      return initialFrequency || {};
    }
    return {};
  });

  const [apiPageCount, setApiPageCount] = useState(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get("page");
    return page ? Number(page) : 0;
  });

  const [btnLoader, setBtnLoader] = useState(false);
  const [exportData, setExportData] = useState({});
  const [modalExportError, setModalExportError] = useState(false);
  const [modalErrorMsg, setModalErrorMsg] = useState("");
  const [payReliefModalError, setPayReliefModalError] = useState(false);
  const [payReliefError, setPayReliefError] = useState("");
  const [totalHoursData, setTotalHoursData] = useState({});

  const [filteredEmployeeData, setFilteredEmployeeData] = useState([]);
  const [pendingCount, setPendingCount] = useState(0);
  const [approvedCount, setApprovedCount] = useState(0);
  const [deniedCount, setDeniedCount] = useState(0);
  const [exportCount, setExportCount] = useState(0);
  const [payweekIds, setPayweekIds] = useState([]);
  const [, setPtoIndividualIds] = useState([]);
  const [modalDiff, setModalDiff] = useState(false);
  const [approveText, setApproveText] = useState("");

  const [showReports, setShowReports] = useState(false);
  const [btnSyncLoader, setBtnSyncLoader] = useState(false);

  const [showPendingModal, setShowPendingModal] = useState(false);
  const [pendingModalData, setPendingModalData] = useState({});
  const [validateText] = useState("");
  const [userPayweekIds, setUserPayweekIds] = useState({});
  const latestCallRef = useRef(null);
  const [apiCallsInProgress, setApiCallsInProgress] = useState(0);

  const handleApiStart = () => {
    setApiCallsInProgress((prev) => prev + 1);
    setLoading(true);
  };

  const handleApiEnd = () => {
    setApiCallsInProgress((prev) => {
      const newCount = prev - 1;
      if (newCount === 0) {
        setLoading(false);
      }
      return newCount;
    });
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get("page");
    const frequency = params.get("frequency");
    if (page !== null) {
      setApiPageCount(apiPageCount);
    }
    if (frequency) {
      const selectedFrequency = frequencyData.find(
        (option) => option.id === Number(frequency)
      );
      if (selectedFrequency) {
        setSelectTagValue(selectedFrequency);
      }
    }
  }, [location.search, frequencyData]);

  const handleApproveAllClick = async () => {
    handleApiStart();
    const subData = allIds.map((item) => ({
      user_id: item,
      pto_ids: [],
      reg_ids: userPayweekIds[item] || [],
    }));
    let obj = {
      pay_frequency_id: selectTagValue?.id,
      pay_start_date: dateRangeData?.start_date,
      pay_end_date: dateRangeData?.end_date,
      approve_type: approveText,
      data: subData,
    };
    try {
      const res = await postData("multitimecard_all_user_approve", {}, obj);
      if (res?.status === true) {
        setAllIds([]);
        setModalOpen(false);
        setLoading(false);
        toast.success(res.message, {
          theme: "colored",
          autoClose: 3000,
          style: {
            borderRadius: "16px",
          },
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        setLoading(false);
        setModalOpen(false);
        toast.error(res.message, {
          theme: "colored",
          autoClose: 3000,
          style: {
            borderRadius: "16px",
          },
        });
      }
    } catch (err) {
      toast.error(err.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
      setLoading(false);
      setModalOpen(false);
    }
  };
  const handlePtoStatus = (e) => {
    setPtoStatus(e);
  };
  const handleFrequencySelect = (e) => {
    if (e) {
      const defaultOption = frequencyData.find((option) => option.id === e.id);
      setSelectTagValue(defaultOption);
      setApiPageCount(0);
      navigate(`?frequency=${e.id}&page=0`);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    navigate(`?frequency=${selectTagValue.id}&page=${page}`);
  };

  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(0);
  };

  const startIndex = currentPage * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const totalPages = Math.ceil(filteredEmployeeData.length / rowsPerPage);

  useEffect(() => {
    const initialCheckedValues = employeeHoursData.map(() => ({
      isChecked: false,
    }));
    setCheckedValues(initialCheckedValues);
  }, [employeeHoursData]);

  const handleMainCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    const updatedCheckedValues = filteredEmployeeData.map((row) => ({
      ...row,
      isChecked: isChecked,
    }));

    setAllChecked(isChecked);
    setCheckedValues(updatedCheckedValues);

    if (isChecked) {
      const allIds = filteredEmployeeData.map((row) => row.user_id);
      const userPayweekIds = filteredEmployeeData.reduce((acc, row) => {
        acc[row.user_id] = row.req_data.payweek_id;
        return acc;
      }, {});
      const requestIds = filteredEmployeeData.flatMap(
        (row) => row.req_data.request_id
      );

      setAllIds(allIds);
      setUserPayweekIds(userPayweekIds);
      setPtoIndividualIds(requestIds);
    } else {
      // Deselect all users
      setAllIds([]);
      setUserPayweekIds({});
      setPtoIndividualIds([]);
    }
  };
  const handleIndividualCheckboxChange = (index) => {
    const updatedCheckedValues = [...checkedValues];
    updatedCheckedValues[index].isChecked =
      !updatedCheckedValues[index].isChecked;
    const ptoId = employeeHoursData[index].user_id;
    const userPayweekIds = employeeHoursData[index].req_data.payweek_id;
    const userRequestIds = employeeHoursData[index].req_data.request_id;

    if (updatedCheckedValues[index].isChecked) {
      setAllIds((prevIds) => [...prevIds, ptoId]);
      setUserPayweekIds((prevUserIds) => ({
        ...prevUserIds,
        [ptoId]: userPayweekIds,
      }));
      setPtoIndividualIds((prevIds) => [...prevIds, ...userRequestIds]);
    } else {
      setAllIds((prevIds) => prevIds.filter((id) => id !== ptoId));
      setUserPayweekIds((prevUserIds) => {
        const newUserIds = { ...prevUserIds };
        delete newUserIds[ptoId];
        return newUserIds;
      });
      setPtoIndividualIds((prevIds) =>
        prevIds.filter((id) => !userRequestIds.includes(id))
      );
    }
    setCheckedValues(updatedCheckedValues);
  };

  useEffect(() => {
    setFilteredEmployeeData(employeeHoursData);
  }, [employeeHoursData]);

  const handleChange = debounce((e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchData(searchValue);
    const filteredData = employeeHoursData.filter((row) => {
      if (row.name && typeof row.name === "string") {
        return row.name.toLowerCase().includes(searchValue);
      }
      return false;
    });
    const pendingFilterCount = filteredData.filter(
      (row) => row.status === "Pending"
    ).length;
    const approvedFilterCount = filteredData.filter(
      (row) => row.status === "Approved"
    ).length;
    const deniedFilterCount = filteredData.filter(
      (row) => row.status === "Denied"
    ).length;

    setFilteredEmployeeData(filteredData.length > 0 ? filteredData : []);

    if (ptoStatus === "pending") {
      setPendingCount(pendingFilterCount);
    } else if (ptoStatus === "approved") {
      setApprovedCount(approvedFilterCount);
    } else if (ptoStatus === "denied") {
      setDeniedCount(deniedFilterCount);
    }
    setAllIds([]);
    setCheckedValues(employeeHoursData.map(() => ({ isChecked: false })));
    setAllChecked(false);
  }, 500);

  const handleClear = () => {
    setSearchData("");
    setFilteredEmployeeData(employeeHoursData);
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };
  const handleViewBtn = (row) => {
    navigate(
      `/multi-card-detail?profileId=${row.user_id}&page=${apiPageCount}`,
      {
        state: { row },
      }
    );
  };
  const handleModalOpen = (btnText) => {
    setModalOpen(true);
    setApproveText(btnText);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };
  const handleExportModal = async (validationText) => {
    setBtnLoader(true);
    setModalDiff(false);
    setExportModalText(
      "Payroll relief has returned the following data about the next available pay period for this schedule."
    );
    let payloadObj = {
      pay_frequency_id: selectTagValue?.id,
      isvalidate: validationText ? validationText : validateText,
    };
    try {
      const res = await postData("prepare_pf", {}, payloadObj);
      if (res?.status === true) {
        setLoading(false);
        setExportModal(true);
        setBtnLoader(false);
        setExportData(res?.data);
        toast.success(res.message, {
          theme: "colored",
          autoClose: 3000,
          style: {
            borderRadius: "16px",
          },
        });
      } else if (
        res?.status === false &&
        (res?.data?.pto || res?.data?.timecard)
      ) {
        setShowPendingModal(true);
        setPendingModalData(res?.data);
        setBtnLoader(false);
        setLoading(false);
      } else if (
        res?.status === false &&
        res?.message === "Unexpected error" &&
        res?.end_date !== "" &&
        res?.start_date !== ""
      ) {
        setExportData(res?.data);
        setLoading(false);
        setExportModal(true);
        setBtnLoader(false);
      } else if (res?.status === false && res?.message) {
        setPayReliefModalError(true);
        setPayReliefError(res?.message);
        setExportModal(true);
        setBtnLoader(false);
      } else {
        setLoading(false);
        setBtnLoader(false);
        toast.error(res.message, {
          theme: "colored",
          autoClose: autocloseTiming,
          style: {
            borderRadius: "16px",
          },
        });
      }
    } catch (err) {
      console.error(err);
      setLoading(false);
      setBtnLoader(false);
    }
  };
  const handleExportModalClose = () => {
    setExportModal(false);
    setModalExportError(false);
    setPayReliefModalError(false);
  };
  const getFrequencyData = async () => {
    handleApiStart();
    try {
      const res = await getData("get-frequency", {});
      if (res?.status === true) {
        setFrequencyData(res.data);
        setLoading(false);
        if (Object.keys(selectTagValue).length === 0) {
          setSelectTagValue(res.data[0]);
        }
      } else {
        setLoading(false);
        toast.error(res.message, {
          theme: "colored",
          autoClose: autocloseTiming,
          style: {
            borderRadius: "16px",
          },
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      handleApiEnd();
    }
  };

  const handleGetUserInfo = async () => {
    handleApiStart();
    const res = await postData("get_user_details", {}, {});
    try {
      if (res?.status === true) {
        setLoading(false);
        setGroupId(res?.data?.group_id);
      } else {
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    } finally {
      handleApiEnd();
    }
  };

  useEffect(() => {
    getFrequencyData();
    handleGetUserInfo();
  }, []);
  const getDateRangeData = async () => {
    handleApiStart();
    let payLoadObj = {
      frequency_id: selectTagValue?.id,
      page: apiPageCount,
    };
    const currentCall = new Date().getTime();
    latestCallRef.current = currentCall;
    try {
      const res = await postData("get-frequency-employee-list", {}, payLoadObj);
      if (latestCallRef.current === currentCall) {
        if (res?.status === true) {
          setLoading(false);
          setDateRangeData(res?.data?.date_range);
          setTotalHoursData(res?.data);
          setPtoPendingData(res?.data?.userData?.pending);
          setPtoApprovedData(res?.data?.userData?.approved);
          setPtoDeniedData(res?.data?.userData?.denied);
          setExportCount(res?.data?.export);
        } else {
          setLoading(false);
          setDateRangeData(res?.data?.date_range);
          toast.error(res.message, {
            theme: "colored",
            autoClose: autocloseTiming,
            style: {
              borderRadius: "16px",
            },
          });
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      handleApiEnd();
    }
  };
  useEffect(() => {
    if (frequencyData.length > 0) {
      getDateRangeData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectTagValue, apiPageCount]);

  const handleLeftArrowClick = () => {
    setApiPageCount(apiPageCount + 1);
  };
  const handleRightArrowClick = () => {
    if (apiPageCount > 0) {
      setApiPageCount(apiPageCount - 1);
    }
  };
  const handlePrepareExport = async () => {
    setBtnSyncLoader(true);
    let payloadObj = {
      payperiod: exportData?.payperiod,
      payperiod_verify: exportData?.payperiod_verify,
      prepared_input: exportData?.prepared_input,
    };
    try {
      const res = await postData("sync_pf", {}, payloadObj);
      if (res?.status === true) {
        setLoading(false);
        setBtnSyncLoader(false);
        setExportModal(false);
        toast.success(res.message, {
          theme: "colored",
          autoClose: 3000,
          style: {
            borderRadius: "16px",
          },
        });
      } else if (res?.status === false && res?.message) {
        setModalExportError(true);
        setModalErrorMsg(res?.message);
        setLoading(false);
        setBtnSyncLoader(false);
      } else {
        setLoading(false);
        setBtnSyncLoader(false);
        toast.error(res.message, {
          theme: "colored",
          autoClose: autocloseTiming,
          style: {
            borderRadius: "16px",
          },
        });
      }
    } catch (err) {
      console.error(err);
      setLoading(false);
      setBtnSyncLoader(false);
    }
  };

  useEffect(() => {
    if (ptoStatus === "pending") {
      setEmployeeHoursData(ptoPendingData);
      setAllIds([]);
      setPayweekIds([]);
    } else if (ptoStatus === "denied") {
      setEmployeeHoursData(ptoDeniedData);
      setAllIds([]);
      setPayweekIds([]);
    } else {
      setEmployeeHoursData(ptoApprovedData);
      setAllIds([]);
      setPayweekIds([]);
    }
  }, [ptoApprovedData, ptoDeniedData, ptoPendingData, ptoStatus]);

  const handleReportsOpen = () => {
    setShowReports(!showReports);
  };
  const handleReportClose = () => {
    setShowReports(false);
  };

  const tableDataArray =
    filteredEmployeeData.length > 0 ? filteredEmployeeData : [];

  const labourUrl = `https://app.guhroo.co/reports/labor_timetracker/${selectTagValue?.id}`;
  const summaryUrl = `https://app.guhroo.co/reports/timetracker/${selectTagValue?.id}`;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showReports && ref.current && !ref.current.contains(event.target)) {
        setShowReports(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showReports]);
  const handlePendingModalClose = () => {
    setShowPendingModal(false);
    handleExportModal("no");
  };
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const frequencyId = params.get("frequency");

    if (frequencyId && frequencyData.length > 0) {
      const updatedFrequency = frequencyData.find(
        (option) => option.id === Number(frequencyId)
      );
      if (updatedFrequency && updatedFrequency.id !== selectTagValue.id) {
        setSelectTagValue(updatedFrequency);
      }
    }
  }, [location.search, frequencyData, selectTagValue]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const currentPage = Number(params.get("page"));
    if (currentPage !== apiPageCount) {
      params.set("page", apiPageCount);
      const newUrl = `${location.pathname}?${params.toString()}`;
      navigate(newUrl, { replace: true });
    }
  }, [apiPageCount, navigate, location]);

  return (
    <>
      {apiCallsInProgress > 0 ? (
        <Loader />
      ) : (
        <>
          <div>
            <div className="time-off-title-card">
              <OnboardHeading title="TIME CARD APPROVAL" />
            </div>
            {/* {groupId === 5 || groupId === 8
            ? null
            : employeeHoursData.length > 0 && ( */}
            <div className="multi-filter-container">
              <div className="multi-card-select-wrapper">
                <div className="multi-card-select-sub-wrapper">
                  <div className="select-frequency-wrapper">
                    <p>Frequency</p>
                    <Select
                      placeholder="Select Frequency"
                      closeMenuOnSelect={true}
                      hideSelectedOptions={false}
                      options={frequencyData.map((option) => ({
                        label: option.label,
                        value: option.id,
                        ...option,
                      }))}
                      onChange={(e) => handleFrequencySelect(e)}
                      value={
                        selectTagValue && {
                          label: selectTagValue.label,
                          value: selectTagValue.id,
                          ...selectTagValue,
                        }
                      }
                    />
                  </div>
                  <div className="select-date-range-wrapper">
                    <p>Date range</p>
                    <div className="date-range-subwrapper">
                      <img
                        src={LeftArrow}
                        alt="left-arrow"
                        onClick={handleLeftArrowClick}
                        className="arrow-image-cus"
                      />
                      <div className="d-flex align-items-center">
                        <p>
                          {moment(dateRangeData?.start_date).format(
                            "MM/DD/YYYY"
                          )}{" "}
                        </p>
                        -
                        <p>
                          {moment(dateRangeData?.end_date).format("MM/DD/YYYY")}{" "}
                        </p>
                      </div>
                      <img
                        src={RightArrow}
                        alt="left-arrow"
                        onClick={
                          apiPageCount === 0 ? null : handleRightArrowClick
                        }
                        className={
                          apiPageCount === 0
                            ? "date-right-arrow-cus"
                            : "arrow-image-cus"
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              {groupId === 5 || groupId === 8 ? null : (
                <div className="multi-card-go-to-reports">
                  <div className="info-btn-wrapper">
                    <button
                      className="btn multi-approval-go-to-button"
                      onClick={handleReportsOpen}
                    >
                      Go to reports <img src={DownArrow} alt="down-arrow" />
                    </button>
                    {showReports && (
                      <div className="go-to-reports-sub-section" ref={ref}>
                        <a
                          href={labourUrl}
                          onClick={handleReportClose}
                          className="go-to-report-lab"
                        >
                          Labor Report
                        </a>
                        <a
                          href={summaryUrl}
                          onClick={handleReportClose}
                          className="go-to-report-summary"
                        >
                          Summary Report
                        </a>
                      </div>
                    )}
                    {ptoPendingData.length > 0 ||
                    ptoApprovedData.length > 0 ||
                    ptoDeniedData.length > 0 ||
                    selectTagValue?.status === null ? (
                      <>
                        {exportCount === 1 ? (
                          <button
                            className="btn btn-primary multi-approval-export-button"
                            onClick={() =>
                              btnLoader ? null : handleExportModal()
                            }
                          >
                            {" "}
                            {btnLoader ? (
                              <Spinner animation="border" size="sm" />
                            ) : (
                              "Export to payroll relief"
                            )}
                          </button>
                        ) : null}
                      </>
                    ) : null}
                  </div>
                </div>
              )}
            </div>
            <div className="multi-card-search-approve-wrapper">
              {" "}
              {groupId === 5 || groupId === 8 ? null : (
                <div className="multi-card-input-search-wrapper">
                  <InputGroup>
                    <img src={SearcIcon} alt="search-icon" />
                    <Form.Control
                      type="text"
                      placeholder="Search for employee by name"
                      onChange={handleChange}
                      ref={inputRef}
                    />{" "}
                    {searchData && <MdClear onClick={handleClear} />}{" "}
                  </InputGroup>
                </div>
              )}
              <div className="overtime-total-hours-wrapper">
                <div className="total-overtime-hours padding-wrapper">
                  <p>Regular</p>
                  <h5>{totalHoursData?.regular || "-"}</h5>
                </div>
                <div className="total-overtime-hours overtime-sub-wrapper">
                  <p>Overtime</p>
                  <h5>{totalHoursData?.overtime || "-"}</h5>
                </div>
              </div>
              {groupId === 5 || groupId === 8 ? null : (
                <>
                  {/* {ptoStatus === "pending" && ( */}
                  <div
                    className={
                      ptoStatus === "pending"
                        ? "d-flex multi-card-approve-all-btn-wrapper"
                        : "d-flex multi-card-approve-all-btn-wrapper"
                    }
                  >
                    {(ptoStatus === "pending" || ptoStatus === "denied") && (
                      <button
                        className={
                          allIds.length > 0
                            ? "approve-btn-active"
                            : "approve-timeoff-btn disabled-button"
                        }
                        onClick={() =>
                          allIds.length > 0 ? handleModalOpen("approve") : null
                        }
                      >
                        APPROVE
                      </button>
                    )}
                    {/* {ptoStatus === "approved" && (
                      <button
                        className={
                          allIds.length > 0
                            ? "approve-btn-active"
                            : "approve-timeoff-btn disabled-button"
                        }
                        onClick={() =>
                          allIds.length > 0 ? handleModalOpen("pending") : null
                        }
                      >
                        UN APPROVE
                      </button>
                    )} */}
                    {(ptoStatus === "pending" || ptoStatus === "approved") && (
                      <button
                        className={
                          allIds.length > 0
                            ? "deny-btn-active"
                            : "deny-timeoff-btn disabled-button"
                        }
                        onClick={() =>
                          allIds.length > 0 ? handleModalOpen("reject") : null
                        }
                      >
                        REJECT
                      </button>
                    )}
                  </div>
                  {/* )} */}
                </>
              )}
            </div>
            {/* )} */}
            <div className="multi-card-status-wrapper">
              <div className="btn-pto-wrapper">
                <button
                  className={
                    ptoStatus === "pending"
                      ? "pto-log-current-btn active"
                      : "pto-log-current-btn"
                  }
                  onClick={() => handlePtoStatus("pending")}
                >
                  Pending({searchData ? pendingCount : ptoPendingData.length})
                </button>
                <button
                  className={
                    ptoStatus === "approved"
                      ? "pto-log-upcoming-btn active"
                      : "pto-log-upcoming-btn"
                  }
                  onClick={() => handlePtoStatus("approved")}
                >
                  Approved({searchData ? approvedCount : ptoApprovedData.length}
                  )
                </button>
                <button
                  className={
                    ptoStatus === "denied"
                      ? "pto-log-denied-btn active"
                      : "pto-log-denied-btn"
                  }
                  onClick={() => handlePtoStatus("denied")}
                >
                  Rejected({searchData ? deniedCount : ptoDeniedData.length})
                </button>
              </div>
              <div className="total-pending-hours">
                <p>Pending</p>
                <h5>{totalHoursData?.pending || "-"}</h5>
              </div>
            </div>
            <>
              <MultiCardTable
                tableDataArray={tableDataArray}
                groupId={groupId}
                ptoStatus={ptoStatus}
                checkedValues={checkedValues}
                handleMainCheckboxChange={handleMainCheckboxChange}
                startIndex={startIndex}
                endIndex={endIndex}
                handleIndividualCheckboxChange={handleIndividualCheckboxChange}
                loginUserId={loginUserId}
                handleViewBtn={handleViewBtn}
                currentPage={currentPage}
                handlePageChange={handlePageChange}
                employeeHoursData={employeeHoursData}
                rowsPerPage={rowsPerPage}
                handleRowsPerPageChange={handleRowsPerPageChange}
                totalPages={totalPages}
              />
            </>
          </div>
          <>
            <ApprovalModal
              modalOpen={modalOpen}
              handleModalClose={handleModalClose}
              approveText={approveText}
              handleApproveAllClick={handleApproveAllClick}
              allIds={allIds}
            />
          </>
          <div>
            <ModalDetail
              show={exportModal}
              closeModal={handleExportModalClose}
              modalText={exportModalText}
              headerText="Please confirm your export"
              multiCardModalDate
              handleModalOnClick={handlePrepareExport}
              btnLoader={btnLoader}
              modalData={exportData}
              modalExportError={modalExportError}
              modalErrorMsg={modalErrorMsg}
              payReliefModalError={payReliefModalError}
              payReliefError={payReliefError}
              modalDiff={modalDiff}
              btnSyncLoader={btnSyncLoader}
            />
          </div>
          <>
            <PendingModal
              pendingModalData={pendingModalData}
              showPendingModal={showPendingModal}
              handlePendingModalClose={handlePendingModalClose}
            />
          </>
        </>
      )}
    </>
  );
}
