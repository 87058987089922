import React, { useState } from "react";
import { Modal, Form, FormControl, FormLabel, Spinner } from "react-bootstrap";
import closebutton from "../../assets/images/multi-card-approval/x.svg";
import deleteicon from "../../assets/images/multi-card-approval/delete-icon.svg";
import editicon from "../../assets/images/multi-card-approval/edit-icon.svg";
import PendingIcon from "../../assets/images/multi-card-approval/pending_icon.svg";
import ApproveIcon from "../../assets/images/multi-card-approval/approveIcon.svg";
import RejectIcon from "../../assets/images/multi-card-approval/rejectedIcon.svg";
import Select from "react-select";
import { postData } from "../../api";
import { toast } from "react-toastify";
import { autocloseTiming } from "../../api/regex";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import moment from "moment";
export default function ViewModal(props) {
  const {
    row,
    groupId,
    modalOpen,
    handleClose,
    modalIndividualData,
    handleDelModalOpen,
    setEditAddEntry,
    editAddEntry,
    editModalInputData,
    handleEditOnChange,
    policyOptions,
    handleApproveData,
    btnLoading,
    setModalOpen,
    getEmployeeCardData,
    setModalDiff,
    calcEditTotalhrs,
    handleApproveRejectAll,
    addModalErrors,
    totalHoursData,
    jobLocation,
  } = props;
  const [btnApproveLoading, setBtnApproveLoading] = useState(false);

  const handleNonEditApproval = async (typeText) => {
    setBtnApproveLoading(true);
    let approvalObj = {
      user_id: modalIndividualData?.user_id,
      ids: [modalIndividualData?.id],
      approve_type: typeText,
    };
    const res = await postData("status_pto", {}, approvalObj);
    try {
      if (res?.status === true) {
        setBtnApproveLoading(false);
        setModalOpen(false);
        toast.success(res?.message, {
          theme: "colored",
          autoClose: autocloseTiming,
        });
        getEmployeeCardData();
        setEditAddEntry(false);
      } else {
        setBtnApproveLoading(false);
        toast.error(res?.message, {
          theme: "colored",
          autoClose: autocloseTiming,
        });
      }
    } catch (err) {
      console.log(err);
      setBtnApproveLoading(false);
    }
  };
  const handleTimeChange = (time, type) => {
    const formattedTime = moment(time).format("HH:mm");
    handleEditOnChange({ target: { value: formattedTime } }, type);
  };
  return (
    <div className="details-modal-wrapper">
      <Modal
        show={modalOpen}
        onHide={handleClose}
        className="modal-detail-wrapper"
      >
        <Modal.Header className="modal-header-wrapper">
          <Modal.Title>
            <h4>
              {modalIndividualData.type === "pto-request"
                ? "Time off preview"
                : "Time card preview"}
            </h4>
          </Modal.Title>
          <div className="modal-close" onClick={handleClose}>
            <img src={closebutton} alt="closebutton" />
          </div>
        </Modal.Header>
        <Modal.Body
          className={
            editModalInputData?.category?.value === -1
              ? "modal-body-wrapper custom-height-wrapper"
              : "modal-body-wrapper"
          }
        >
          <div className="status-flex-container">
            <p className="day">{modalIndividualData?.date_label}</p>
            {modalIndividualData.status === "Rejected" ? null : (
              <div className="delete-edit-wrapper">
                {modalIndividualData.type === "empty" ||
                (modalIndividualData.type === "pto-request" &&
                  modalIndividualData.status === "Approved" &&
                  groupId === 5) ||
                (groupId === 8 &&
                  modalIndividualData.type === "pto-request" &&
                  modalIndividualData.status === "Approved") ? null : (
                  <img
                    src={deleteicon}
                    alt="delete-icon"
                    onClick={() => {
                      setModalDiff(true);
                      handleDelModalOpen(
                        `Are you sure you want to delete ${
                          groupId === 5 || groupId === 8
                            ? totalHoursData?.emp_data?.name
                            : row?.name
                            ? row?.name
                            : totalHoursData?.emp_data?.name
                        } ${
                          modalIndividualData.type === "pto-request"
                            ? "time off card"
                            : "time card"
                        } for ${modalIndividualData?.date_label}?`
                      );
                    }}
                  />
                )}
                {modalIndividualData.type === "pto-request" ||
                modalIndividualData.type === "Running" ||
                modalIndividualData.type === "empty" ? null : (
                  <>
                    {modalIndividualData.status === "Pending" ? (
                      <img
                        src={editicon}
                        alt="edit-icon"
                        onClick={() => setEditAddEntry(true)}
                      />
                    ) : null}
                  </>
                )}
              </div>
            )}
          </div>{" "}
          <div className="status-detail-wrapper">
            <img
              src={
                modalIndividualData?.status === "Pending"
                  ? PendingIcon
                  : modalIndividualData?.status === "Approved"
                  ? ApproveIcon
                  : RejectIcon
              }
              alt="pending-icon"
            />{" "}
            <p>{modalIndividualData?.status}</p>
          </div>
          <div className="form-wrapper">
            <Form>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                {modalIndividualData.type === "pto-request" ? null : (
                  <div className="clock-in-out-wrapper">
                    <div className="clock-in-out">
                      <FormLabel>Clock In</FormLabel>
                      <div className="sub-clock-in-out-wrapper">
                        {" "}
                        <TimePicker
                          disabled={!editAddEntry}
                          value={
                            editModalInputData?.timeIn
                              ? moment(
                                  editModalInputData?.timeIn,
                                  "HH:mm"
                                ).toDate()
                              : null
                          }
                          onChange={(time) => handleTimeChange(time, "timeIn")}
                          ampm={true}
                          views={["hours", "minutes"]}
                          desktopModeMediaQuery="(max-width: 10000px)"
                        />
                        {addModalErrors.timeIn && (
                          <div className="invalid-feedback d-block">
                            {addModalErrors.timeIn}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="clock-in-out">
                      <FormLabel>Clock Out</FormLabel>
                      <div className="sub-clock-in-out-wrapper">
                        <TimePicker
                          disabled={!editAddEntry}
                          value={
                            editModalInputData?.timeOut === "0-:undefined"
                              ? null
                              : editModalInputData?.timeOut
                              ? moment(
                                  editModalInputData?.timeOut,
                                  "HH:mm"
                                ).toDate()
                              : null
                          }
                          onChange={(time) => handleTimeChange(time, "timeOut")}
                          ampm={true}
                          views={["hours", "minutes"]}
                          desktopModeMediaQuery="(max-width: 10000px)"
                        />
                        {addModalErrors.timeOut && (
                          <div className="invalid-feedback d-block">
                            {addModalErrors.timeOut}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </LocalizationProvider>
              <div className="total-hours">
                <FormLabel>Total Hours</FormLabel>
                <FormControl
                  type="text"
                  placeholder=""
                  disabled={true}
                  value={
                    !editAddEntry ? modalIndividualData.hours : calcEditTotalhrs
                  }
                  onChange={(e) => handleEditOnChange(e, "totalHours")}
                />
              </div>
              {/* {groupId === 5 || groupId === 8 ? null : ( */}
              <div className="category">
                <FormLabel>Category</FormLabel>
                <Select
                  placeholder="Select Category"
                  isDisabled={true}
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  value={editModalInputData?.category}
                  options={policyOptions.map((option) => ({
                    value: option.id,
                    label: option.name,
                  }))}
                  onChange={(value) => handleEditOnChange(value, "category")}
                />
              </div>
              {/* )} */}
              {editModalInputData?.category?.value === -1 && (
                <>
                  <div className="job-main-location-wrapper">
                    {/* {jobLocation?.options.length > 0 && ( */}
                    <>
                      {editModalInputData?.jobLocation !== "" ? (
                        <div className="job-location-wrapper">
                          <FormLabel>Job location</FormLabel>
                          <Select
                            placeholder="Select Location"
                            isDisabled={
                              !editAddEntry || jobLocation?.options.length === 0
                            }
                            options={jobLocation?.options.map((option) => ({
                              label: option.name,
                              value: option.id,
                              ...option,
                            }))}
                            onChange={(value) =>
                              handleEditOnChange(value, "jobLocation")
                            }
                            value={
                              editModalInputData?.jobLocation
                                ? {
                                    label:
                                      editModalInputData.jobLocation.title ||
                                      editModalInputData.jobLocation.name ||
                                      "Select Location",
                                    value:
                                      editModalInputData.jobLocation.id || null,
                                  }
                                : null
                            }
                          />
                          {addModalErrors.jobLocation && (
                            <div className="invalid-feedback d-block">
                              {addModalErrors.jobLocation}
                            </div>
                          )}
                        </div>
                      ) : null}
                    </>
                    {/* )} */}
                    {/* {jobLocation?.code?.length > 0 && ( */}
                    <>
                      {editModalInputData?.jobCode !== "" ? (
                        <div className="job-code-wrapper">
                          <FormLabel>Job code</FormLabel>
                          <Select
                            placeholder="Select Code"
                            isDisabled={
                              !editAddEntry || jobLocation?.code?.length === 0
                            }
                            options={jobLocation?.code.map((option) => ({
                              label: option.name,
                              value: option.id,
                              ...option,
                            }))}
                            onChange={(value) =>
                              handleEditOnChange(value, "jobCode")
                            }
                            value={
                              editModalInputData?.jobCode
                                ? {
                                    label:
                                      editModalInputData.jobCode.title ||
                                      editModalInputData.jobCode.name,
                                    value: editModalInputData.jobCode.id,
                                  }
                                : null
                            }
                          />
                          {addModalErrors.jobCode && (
                            <div className="invalid-feedback d-block">
                              {addModalErrors.jobCode}
                            </div>
                          )}
                        </div>
                      ) : null}
                    </>
                    {/* )} */}
                  </div>
                </>
              )}
              <div className="notes">
                <FormLabel>Notes</FormLabel>
                <FormControl
                  as="textarea"
                  disabled={!editAddEntry}
                  className="height-class-input"
                  value={editModalInputData?.notes}
                  onChange={(e) => handleEditOnChange(e, "notes")}
                />{" "}
                {addModalErrors.notes && (
                  <div className="invalid-feedback d-block">
                    {addModalErrors.notes}
                  </div>
                )}
              </div>
            </Form>
          </div>
        </Modal.Body>{" "}
        {modalIndividualData.status === "Rejected" ||
        modalIndividualData.type === "pto-request" ? null : (
          <Modal.Footer className="modal-detail-main-wrapper">
            {modalIndividualData.status === "Pending" && editAddEntry ? (
              <button
                className="approve-btn-active"
                onClick={() =>
                  btnLoading || btnApproveLoading
                    ? null
                    : modalIndividualData.type === "pto-request"
                    ? handleNonEditApproval("approve")
                    : handleApproveData()
                }
              >
                {btnLoading || btnApproveLoading ? (
                  <Spinner animation="border" size="sm" />
                ) : modalIndividualData.type === "pto-request" ||
                  modalIndividualData.type === "Running" ||
                  modalIndividualData.type === "empty" ? (
                  groupId === 5 || groupId === 8 ? (
                    "EDIT REQUEST"
                  ) : (
                    "APPROVE"
                  )
                ) : groupId === 5 || groupId === 8 ? (
                  "EDIT REQUEST"
                ) : (
                  "EDIT & APPROVE"
                )}
              </button>
            ) : null}
            {groupId === 5 || groupId === 8 ? null : (
              <>
                {modalIndividualData.status === "Approved" ? (
                  <button
                    className="approve-btn-active"
                    onClick={() =>
                      btnApproveLoading
                        ? null
                        : modalIndividualData.category === "Regular"
                        ? handleApproveRejectAll("pending")
                        : handleNonEditApproval("pending")
                    }
                  >
                    {btnApproveLoading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "UN APPROVE"
                    )}
                  </button>
                ) : null}
                {modalIndividualData.status === "Approved" ||
                (modalIndividualData.status === "Pending" && editAddEntry) ? (
                  <button
                    className="deny-btn-active"
                    onClick={() =>
                      btnApproveLoading
                        ? null
                        : modalIndividualData.category === "Regular"
                        ? handleApproveRejectAll("reject")
                        : handleNonEditApproval("reject")
                    }
                  >
                    {btnApproveLoading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "REJECT"
                    )}
                  </button>
                ) : null}
              </>
            )}
          </Modal.Footer>
        )}
        {modalIndividualData.status !== "Rejected" ||
        modalIndividualData.type === "pto-request" ? null : (
          <Modal.Footer className="modal-detail-main-wrapper">
            {groupId === 5 || groupId === 8 ? null : (
              <>
                {modalIndividualData.status === "Rejected" ? (
                  <button
                    className="approve-btn-active"
                    onClick={() =>
                      btnApproveLoading
                        ? null
                        : modalIndividualData.category === "Regular"
                        ? handleApproveRejectAll("pending")
                        : handleNonEditApproval("pending")
                    }
                  >
                    {btnApproveLoading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "UN APPROVE"
                    )}
                  </button>
                ) : null}
              </>
            )}
          </Modal.Footer>
        )}
      </Modal>
    </div>
  );
}
