import React from "react";

export default function TabsChange(props) {
  const {
    ptoStatus,
    handlePtoStatus,
    pendingCount,
    approvedCount,
    deniedCount,
  } = props;
  return (
    <div className="multi-card-status-wrapper">
      <div className="btn-pto-wrapper">
        <button
          className={
            ptoStatus === "pending"
              ? "multi-card-status-current-btn active"
              : "multi-card-status-current-btn"
          }
          onClick={() => handlePtoStatus("pending")}
        >
          Pending({pendingCount})
        </button>
        <button
          className={
            ptoStatus === "approved"
              ? "multi-card-status-current-btn active"
              : "multi-card-status-current-btn"
          }
          onClick={() => handlePtoStatus("approved")}
        >
          Approved({approvedCount})
        </button>
        <button
          className={
            ptoStatus === "denied"
              ? "multi-card-status-current-btn active"
              : "multi-card-status-current-btn"
          }
          onClick={() => handlePtoStatus("denied")}
        >
          Rejected({deniedCount})
        </button>
      </div>
    </div>
  );
}
